import React from 'react';
import {
  FaGithubAlt,
  FaLinkedin,
  FaStackOverflow,
  FaProductHunt,
  FaTwitterSquare,
} from 'react-icons/fa';

const data = [
  {
    id: 1,
    icon: <FaLinkedin className="social-icon"></FaLinkedin>,
    url: 'https://www.linkedin.com/in/josephmchambers/',
  },
  {
    id: 2,
    icon: <FaGithubAlt className="social-icon"></FaGithubAlt>,
    url: 'https://github.com/imcodingideas/',
  },
  {
    id: 3,
    icon: <FaStackOverflow className="social-icon"></FaStackOverflow>,
    url: 'http://stackoverflow.com/users/1230013/joseph-chambers',
  },
  {
    id: 4,
    icon: <FaProductHunt className="social-icon"></FaProductHunt>,
    url: 'https://www.producthunt.com/@joseph_chambers',
  },
  {
    id: 5,
    icon: <FaTwitterSquare className="social-icon"></FaTwitterSquare>,
    url: 'https://twitter.com/imcodingideas',
  },
];
const links = data.map(link => (
  <li key={link.id}>
    <a href={link.url} className="social-link" target="_blank" rel="noreferrer">
      {link.icon}
    </a>
  </li>
));

export default ({ styleClass }) => (
  <ul className={`social-links ${styleClass || ''}`}>{links}</ul>
);
