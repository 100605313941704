import React from 'react';
import { FaAlignRight } from 'react-icons/fa';
import { Link } from 'gatsby';
import logo from '../assets/logo.png';
import PageLinks from '../constants/links';

const Navbar = ({ toggleSidebar }) => (
  <nav className="navbar">
    <div className="nav-center">
      <div className="nav-header">
        <Link to="/">
          <img src={logo} alt="Logo" />
        </Link>
        <button type="button" className="toggle-btn" onClick={toggleSidebar}>
          <FaAlignRight></FaAlignRight>
        </button>
      </div>
      <PageLinks styleClass="nav-links"></PageLinks>
    </div>
  </nav>
);

export default Navbar;
